import { useState } from 'react';
import { Form as FormikForm, Formik } from 'formik';
import { t, Trans } from '@lingui/macro';

import { Modal } from 'components/common/Modal/Modal';
import { newsletterFormSchema } from 'helpers/formSchemas/newsletterForm.schema';
import { Button } from 'components/common/Button';
import useNewsletterSubscribe from 'api/header/useNewsletterSubscribe';
import TextInput from 'components/common/inputs/TextInput';
import Checkbox from 'components/common/inputs/Checkbox';
import { FormResetOnRouteChangeHandler } from 'components/FormResetOnRouteChangeHandler';

export default function NewsletterForm() {
  const [showThanks, setShowThanks] = useState(null);
  const newsletterSubscribe = useNewsletterSubscribe();

  const handleSubmit = async (values, api) => {
    try {
      await newsletterSubscribe({
        email: values.email,
        newsletter: true,
        daily: values.daily,
      });
      setShowThanks(values.daily ? 'daily' : 'no-daily');
    } catch (error) {
      console.error(error);
    }

    api.resetForm();
  };

  return (
    <>
      <Formik
        initialValues={{
          email: '',
          daily: false,
        }}
        validationSchema={newsletterFormSchema}
        onSubmit={handleSubmit}
        component={() => (
          <FormikForm id="newsletter-form">
            <div className="mb-2 flex">
              <div className="flex-1">
                <TextInput
                  className="w-full text-black !text-sm rounded-md border border-qw-gray-300 !p-1"
                  type="email"
                  name="email"
                  placeholder={t`Enter your email`}
                />
              </div>
              <div className="mx-6">
                <Button type="submit" size="sm">
                  JOIN!
                </Button>
              </div>
            </div>

            <Checkbox
              id="footer-daily"
              label={t`Also sign up for Daily Update Mail, Get €2/£2/$2 discount code and never miss a tee!`}
              name="daily"
            />
            <FormResetOnRouteChangeHandler />
          </FormikForm>
        )}
      />

      <Modal
        id="footer-newsletter-thanks"
        isOpen={showThanks !== null}
        onClose={() => setShowThanks(null)}
        title={t`THANKS!`}
      >
        <span className="px-12 font-bold">
          {showThanks === 'daily' && (
            <Trans>
              You have successfully been signed up to receive our newsletter and
              daily tee mail! Thanks again!
            </Trans>
          )}
          {showThanks === 'no-daily' && (
            <Trans>
              You have successfully been signed up to receive our newsletter!
              Thanks again!
            </Trans>
          )}
        </span>
      </Modal>
    </>
  );
}
